import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css'; // Updated path to the styles folder

function Header() {
  return (
    <header>
      <div className="container">
        <div className="logo">
          <Link to="/">
            <img src="/images/logo.png" alt="Benillionaire Wealth Ventures Logo" />
          </Link>
        </div>
        <nav>
          <ul className="nav-links">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
