import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/Contact.css';

function Contact() {
  return (
    <div className="contact">
      <Helmet>
        <title>Contact Us | Benillionaire Wealth Ventures</title>
        <meta name="description" content="Get in touch with Benillionaire Wealth Ventures." />
      </Helmet>
      <h2>Contact Us</h2>
      <p>Email: info@benillionairewealth.com</p>
      <p>Phone: (123) 456-7890</p>
    </div>
  );
}

export default Contact;
