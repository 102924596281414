import React from 'react';
import '../styles/Footer.css';

function Footer() {
  return (
    <footer>
      <div className="footer-cta">
        <h3>Join Us Today</h3>
        <button>Sign Up</button>
      </div>
      <p>&copy; 2023 Benillionaire Wealth Ventures. All rights reserved.</p>
    </footer>
  );
}

export default Footer;