import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/Home.css';
import bgVideo from '../assets/animations/SCIFI-BG.mp4';

function Home() {
  useEffect(() => {
    window.addEventListener('scroll', reveal);
    return () => window.removeEventListener('scroll', reveal);
  }, []);

  const reveal = () => {
    const reveals = document.querySelectorAll('.reveal');
    for (let i = 0; i < reveals.length; i++) {
      const windowHeight = window.innerHeight;
      const elementTop = reveals[i].getBoundingClientRect().top;
      const revealPoint = 150;
      if (elementTop < windowHeight - revealPoint) {
        reveals[i].classList.add('active');
      } else {
        reveals[i].classList.remove('active');
      }
    }
  };

  return (
    <div className="home">
      <Helmet>
        <title>Benillionaire Wealth Ventures | Home</title>
        <meta name="description" content="Data portal with GPT insights for small businesses." />
      </Helmet>


      {/* Hero Section */}
      <section className="hero">
      <video
        autoPlay
        muted
        loop
        playsInline
        preload="none"
        id="background-video"
        poster="/assets/images/poster.jpg"
      >
        <source src={bgVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
        <h2>Unlock Insights, Drive Modernity</h2>
        <p>Our data portal empowers small businesses with AI-driven insights to refine strategies and boost profits.</p>
        <button>Get Started</button>
      </section>

      {/* Features Section */}
      <section className="features reveal">
        <h3>COMING SOON</h3>
        {/* <div className="feature-list">
          <div className="feature-item">
            <h4>AI-Powered Analytics</h4>
            <p>Leverage GPT models to gain deep insights into your business data.</p>
          </div>
          <div className="feature-item">
            <h4>User-Friendly Interface</h4>
            <p>Intuitive design makes data analysis accessible to everyone.</p>
          </div>
          <div className="feature-item">
            <h4>Customizable Dashboards</h4>
            <p>Create personalized views to focus on what matters most.</p>
          </div>
        </div> */}
      </section>

      {/* Testimonials Section */}
      {/* <section className="testimonials reveal">
        <h3>What Our Clients Say</h3>
        <div className="testimonial-list">
          <div className="testimonial-item">
            <p>"This platform transformed our decision-making process."</p>
            <h5>- Jane Doe, CEO of Startup Inc.</h5>
          </div>
          <div className="testimonial-item">
            <p>"We saw a 30% increase in profits within three months."</p>
            <h5>- John Smith, Founder of Tech Solutions</h5>
          </div>
        </div>
      </section> */}

      {/* How It Works Section */}
      {/* <section className="how-it-works reveal">
        <h3>How It Works</h3>
        <ol>
          <li>Upload your business data securely.</li>
          <li>Our GPT models analyze and generate insights.</li>
          <li>Implement data-driven strategies to grow your business.</li>
        </ol>
      </section> */}

      {/* Call to Action Section */}
      {/* <section className="cta reveal">
        <h3>Ready to Transform Your Business?</h3>
        <button>Contact Us Today</button>
      </section> */}
    </div>
  );
}

export default Home;
