import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/About.css';

function About() {
  return (
    <div className="about">
      <Helmet>
        <title>About Us | Benillionaire Wealth Ventures</title>
        <meta name="description" content="Learn more about Benillionaire Wealth Ventures." />
      </Helmet>
      <h2>About Us</h2>
      <p>
        At Benillionaire Wealth Ventures, we specialize in leveraging advanced GPT models to transform your business data into actionable insights.
      </p>
    </div>
  );
}

export default About;
